import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useProject } from 'Client/utils/hooks';
import { ContributionGaudi } from 'Shared/types/contribution';
import { ProjectStage } from 'Shared/types';
import { ContributionType } from 'Shared/types/contribution';
import { Link } from 'Atoms';

export interface ThankPageMessageProps {
  contribution: ContributionGaudi; // agreement, comment
  title: string;
  isMap: boolean;
}

export const ContinueMessage: React.FC<ThankPageMessageProps> = ({
  contribution,
  title,
}) => {
  if (!contribution) return null;

  const { type } = contribution;
  const { t } = useTranslation();
  const project = useProject();

  if (
    project.stage === ProjectStage.ACTIVE ||
    project.stage === ProjectStage.TESTING
  )
    return (
      <>
        {type === ContributionType.AGREEMENT && (
          <p>
            <Trans values={{ title }}>
              {'Continue where you left off, '}
              {/* link to contribution page, scrolled down to the contribution that was just agreed with */}
              <Link
                noPadding
                to={`/contributions?scrollTo=${contribution._id}`}
              >
                {'return to contributions for {{title}}.'}
              </Link>
            </Trans>
          </p>
        )}
      </>
    );

  /* if project.stage === completed, closed, archived */
  // TODO: fix plural here
  return (
    <p>
      {t('View more {{ consultationLabel }}s', {
        consultationLabel: project.consultationLabel || 'proposal',
      })}
    </p>
  );
};
