import styled from 'styled-components';
import { Container } from 'Atoms';

export const SetWidthContainer = styled(Container)`
  width: 43.75rem;
`;

export const DivisionContainer = styled(Container)`
  width: 50%;
  height: 100%;
  padding: 0;
  display: flex;
  flex-direction: column;
  padding-top: 5.5rem;
  padding-bottom: 5.625rem;
  justify-content: flex-start;
  align-items: center;

  h1 {
    ${({ theme }) => theme.breakpoints.down('sm')} {
      max-width: 60%;
    }
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    width: 100%;
    height: unset;
  }
`;

export const ThanksPageNextTileContainer = styled(DivisionContainer)`
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: ${({ theme }) => theme.colors.grey[50]};
  padding: 2rem 0;
  height: 100%;
`;

export const FlexContainer = styled.div`
  width: 100%;
  height: 39rem;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    display: block;
    height: auto;
  }
`;

export const FullWidthContainer = styled.div<{ thanksPageV2?: boolean }>`
  width: 100%;
  ${({ thanksPageV2 }) =>
    thanksPageV2
      ? `
      margin-bottom: 0;
    `
      : `
    margin-bottom: 2rem;
    & + & {
      border-top: 0.063rem solid ${({ theme }) =>
        theme.colorMappings.separators};
    }
    `}
`;

export const DarkFullWidthContainer = styled(FullWidthContainer)`
  background-color: ${({ theme }) => theme.colorMappings.pageBackgroundColor};
`;

export const CompleteStateContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.white[500]};
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 100%;
`;
