import { captureException } from '@sentry/node';

interface GetCustomerProjectsProps {
  customer: string;
  currentProject?: string;
  activeOnly?: boolean;
}
/**
 *
 * @param customer customer name (string)
 * @param currentProject project to be ignored, if given (_id)
 * @param activeOnly if true, only active projects will be returned
 * @returns
 */
export const getCustomerProjects = async ({
  customer,
  currentProject,
  activeOnly = false,
}: GetCustomerProjectsProps) => {
  try {
    const res = await fetch(
      `/api/project/customerProjects?customer=${customer}${
        currentProject && `&currentProject=${currentProject}`
      }${activeOnly && `&activeOnly=${activeOnly}`}`
    );
    const jsonRes = (await res.json()) || null;
    return jsonRes?.data?.projects;
  } catch (error) {
    captureException(error);
    return null;
  }
};
