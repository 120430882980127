import * as React from 'react';
import { useProject } from 'Client/utils/hooks';
import { OtherProposalsReel } from 'Pages/proposals';
import { ThanksPageOtherPlanningAppsProps } from '../types';

export const OtherPlanningApps: React.FC<ThanksPageOtherPlanningAppsProps> = ({
  otherPlanningApps,
  otherPlanningAppsState,
}) => {
  const project = useProject();
  const planningAppsToShow = otherPlanningApps || otherPlanningAppsState;

  return (
    <OtherProposalsReel
      planningApps={planningAppsToShow}
      project={project}
      showUnderline={false}
    />
  );
};
