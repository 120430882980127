import * as React from 'react';
import { Box } from '@material-ui/core';
import { useProject } from 'Client/utils/hooks';
import { HtmlDescription } from 'Client/components/molecules';

export const CustomThanks: React.FC = () => {
  const project = useProject();

  if (project.customThanksPage)
    return (
      <Box marginTop={3} marginBottom={1.5}>
        <HtmlDescription>{project.customThanksPage}</HtmlDescription>
      </Box>
    );
};
