import { captureException } from '@sentry/node';

interface GetNearestProjectProps {
  latitude: number;
  longitude: number;
  currentProjectId: string;
  activeOnly?: boolean;
  limit?: number;
  maxDistance?: number;
  spherical?: boolean;
  includeInternal?: boolean;
  customer?: string;
}
/**
 *
 * @param latitude
 * @param longitude
 * @param currentProjectId project to be ignored, if given (_id)
 * @param activeOnly if true, only active projects will be returned
 * @param limit limit the number of projects returned
 * @param maxDistance limit the distance of projects returned
 * @param spherical if true, the distance will be calculated using a spherical model
 * @param includeInternal if true, internal projects will be included (demo, training, template, promote, inactive, customer = commonplace)
 * @param customer if provided, only projects for the given customer will be returned
 * @returns
 */
export const getNearestProjects = async ({
  latitude,
  longitude,
  currentProjectId,
  activeOnly = false,
  limit,
  maxDistance,
  spherical = true,
  includeInternal = false,
  customer,
}: GetNearestProjectProps) => {
  try {
    const res = await fetch(
      `/api/project/nearestProjects?latitude=${latitude}&longitude=${longitude}&currentProjectId=${currentProjectId}${
        activeOnly ? `&activeOnly=${activeOnly}` : ''
      }${limit ? `&limit=${limit}` : ''}${
        maxDistance ? `&maxDistance=${maxDistance}` : ''
      }${spherical ? `&spherical=${spherical}` : ''}${
        includeInternal ? `&includeInternal=${includeInternal}` : ''
      }${customer ? `&customer=${customer}` : ''}
      `
    );
    const jsonRes = (await res.json()) || null;
    return jsonRes?.data?.projects;
  } catch (error) {
    captureException(error);
    return null;
  }
};
