import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Link } from 'Atoms';
import { ContributionGaudi, ContributionType } from 'Shared/types/contribution';

export interface ThankPageMessageProps {
  contribution: ContributionGaudi; // agreement, comment
  title: string;
  commentedOnAllProposals?: boolean;
  isMap?: boolean;
}

const ThanksContainer = styled.div`
  text-align: center;
  color: ${({ theme }) => theme.colorMappings.greyTextDarker};
  max-width: 35rem;
  margin: auto;
  font-size: ${({ theme }) => theme.fontSizes.l};
`;

export const ThanksMessage: React.FC<ThankPageMessageProps> = ({
  contribution,
  title,
  isMap,
  commentedOnAllProposals,
}) => {
  const { i18n } = useTranslation();

  const contributionLink = contribution?.userId
    ? `/contributions/user/${contribution.userId}`
    : `/contributions`;

  return (
    <ThanksContainer>
      {contribution && (
        <>
          <p>
            <Trans values={{ type: contribution.type }}>
              <Link noPadding to={contributionLink}>
                {'Your {{ type }}'}
              </Link>
              {' can now be seen by others and the project team.'}
            </Trans>
          </p>
          {isMap && (
            <p>
              <Trans values={{ title }}>
                {'Continue where you left off, '}
                <Link
                  noPadding
                  to={window.location.href.replace('/thanks', '?haveYourSay=1')}
                >
                  add more comments
                </Link>
                {' or '}
                <Link
                  to={window.location.href.replace('/thanks', '')}
                  noPadding
                >
                  {'check out the contributions for {{title}}.'}
                </Link>
              </Trans>
            </p>
          )}
          {!isMap && (
            <p>
              <Trans>
                We will let you know about updates or reactions to your
                contributions. Make sure you{' '}
                <Link
                  noPadding
                  to={`https://profile.commonplace.is/?lang=${i18n.language}`}
                >
                  opted into communications
                </Link>{' '}
                or take a look at{' '}
                <Link noPadding to="/news">
                  project news
                </Link>
                .
              </Trans>
            </p>
          )}
          {contribution.type === ContributionType.COMMENT &&
            commentedOnAllProposals && (
              <p>
                <Trans>
                  {
                    'Great work, you’ve read all the information available at the moment. Keep an eye out for emails for '
                  }
                  <Link noPadding to="/news">
                    project updates
                  </Link>
                  .
                </Trans>
              </p>
            )}
        </>
      )}
    </ThanksContainer>
  );
};
