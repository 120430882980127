import styled from 'styled-components';

export const Wrapper = styled.div<{ size?: string }>`
  padding: ${({ theme }) => theme.screenPadding};
  width: 100%;
  max-width: ${({ theme, size }) =>
    size === 'small' ? theme.container.smallWidth : theme.container.width};
  margin: 0 auto;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  & > p {
    margin: 0;
  }
`;
