import * as React from 'react';
import styled from 'styled-components';

interface Props {
  totalSurveys?: number;
  totalComments?: number;
}

export const ProgressBars: React.FC<Props> = ({
  totalSurveys,
  totalComments,
}) => {
  const widthPercent = 100 / (totalSurveys + totalComments);
  const activeArray = new Array(totalComments).fill(true);
  const leftToComment = new Array(totalSurveys - totalComments).fill(false);

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        alignContent: 'center',
        justifyContent: 'center',
      }}
    >
      {activeArray.map((_, index) => (
        <Pill key={`${index}-active`} width={widthPercent} active />
      ))}
      {leftToComment.map((_, index) => (
        <Pill key={`${index}-leftToComment`} width={widthPercent} />
      ))}
    </div>
  );
};

const Pill = styled.span<{ width: number; active?: boolean }>`
  width: ${({ width }) => width}%;
  height: 0.8125rem;
  background-color: ${({ theme, active }) =>
    active ? theme.colors.green[400] : theme.colors.grey[400]};
  border-radius: 0.5rem;
`;
