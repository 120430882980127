import * as React from 'react';
import { useProject } from 'Client/utils/hooks';
import { OtherProposalsReel } from 'Pages/proposals';
import { ThanksPageOtherProposalsProps } from '../types';

export const OtherProposals: React.FC<ThanksPageOtherProposalsProps> = ({
  otherProposals,
  otherProposalsState,
  ...props
}) => {
  const project = useProject();
  const proposalsForReel = otherProposals || otherProposalsState;

  if (!proposalsForReel || proposalsForReel.length === 0) return null;

  return (
    <OtherProposalsReel
      proposals={proposalsForReel}
      project={project}
      showUnderline={false}
      {...props}
    />
  );
};
