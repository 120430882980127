import * as React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ContributionType } from 'Shared/types/contribution';
import { HeaderTick } from '../icons/HeaderTick';

export interface ThankPageMessageProps {
  type: string;
  hideTick?: boolean;
}

const HeaderContainer = styled.h1`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  font-size: 2rem;
  font-weight: 700;
  & > * {
    font-family: ${({ theme }) => theme.fontFamily};
  }
  svg {
    margin-right: 0.5rem;
  }
`;

export const Title: React.FC<ThankPageMessageProps> = ({
  type,
  hideTick = false,
}) => {
  const { t } = useTranslation();
  let title = '';

  switch (type) {
    case ContributionType.AGREEMENT:
      title = t(`Thanks for your agreement!`);
      break;
    case ContributionType.COMMENT:
      title = t(`Thanks for this contribution!`);
      break;
    default:
      title = t(`You're all set!`);
  }

  return (
    <HeaderContainer data-testid="thanks-page-title">
      {!hideTick && <HeaderTick />}
      {title}
    </HeaderContainer>
  );
};
