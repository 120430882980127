import { captureException } from '@sentry/node';
import { getPlanningAppTitle } from 'Client/pages/planningapps/utils';
import { fetchContributionById } from 'Client/services/contributions';
import { fetchPlanningAppPage } from 'Client/services/planningapp/fetchPlanningAppPage';
import { getProposalTitle } from 'Client/services/proposals';
import { ContributionGaudi, ContributionType } from 'Shared/types/contribution';
import { PlanningAppDatabases } from 'Shared/types/planningapp';

const FROM_GAUDI_DB = true;

type Params = {
  contribution: ContributionGaudi;
  contributionType: ContributionType;
  commentId?: string;
  projectId: string;
  isPlanningApp?: boolean;
  lang?: string;
  isGla?: boolean;
};

export const getProposalTitleFromToken = async ({
  contribution,
  contributionType,
  commentId,
  projectId,
  isPlanningApp,
  lang = 'en-GB',
  isGla,
}: Params): Promise<string> => {
  try {
    let contr = contribution;
    if (contributionType === ContributionType.AGREEMENT) {
      contr = (await fetchContributionById(
        commentId,
        FROM_GAUDI_DB
      )) as ContributionGaudi;
    }
    if (isPlanningApp) {
      if (isGla) {
        const { planningAppPage } = await fetchPlanningAppPage({
          slug: contr.sectionId,
          origin: PlanningAppDatabases.POSTGRES,
        });
        return getPlanningAppTitle(planningAppPage.address);
      }

      const { planningAppPage } = await fetchPlanningAppPage({
        slug: contr.sectionId,
        origin: PlanningAppDatabases.MONGODB,
      });
      return getPlanningAppTitle(planningAppPage.content.address);
    }
    // TODO: use the pageId instead of the slug
    const title = await getProposalTitle({
      slug: contr.sectionId,
      projectId: projectId,
      lang,
    });
    return title;
  } catch (err) {
    captureException(err);
  }
};
