import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useProject } from 'Client/utils/hooks';
import { fetchContributionsCount } from 'Client/services/contributions/fetchContributionsCount';
import { ContributionGaudi, ContributionType } from 'Shared/types/contribution';
import { ReactionIcon } from 'Atoms/Icons';
import { StatsContribution } from '../icons/StatsContributions';
import {
  StatsContainer,
  Spinner,
  DataRow,
  DataPoint,
  DataPointIcon,
  StatsTopSection,
} from './Stats.style';

export interface StatsProps {
  contribution: ContributionGaudi;
}

export const Stats: React.FC<StatsProps> = ({ contribution, children }) => {
  const { t } = useTranslation();
  const project = useProject();
  const [agreements, setAgreements] = React.useState(null);
  const [contributions, setContributions] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  const displayAgreements = project.features.agreeComments;

  // Get counts for whole project.
  React.useEffect(() => {
    if (!contribution) return;

    const projectId = project._id;
    const surveySlug = contribution.surveySlug as string;

    setLoading(true);

    const getContributions = fetchContributionsCount({
      projectId,
      surveySlug,
      type: ContributionType.COMMENT,
    });

    const getAgreements = fetchContributionsCount({
      projectId,
      surveySlug,
      type: ContributionType.AGREEMENT,
    });

    Promise.all([getContributions, getAgreements]).then(async () => {
      setContributions(await getContributions);
      setAgreements(await getAgreements);
      setLoading(false);
    });
  }, [contribution]);

  const renderData = !loading && agreements !== null && contributions !== null;

  const getContributionStatus = (contributionsCount: number) => {
    switch (contributionsCount) {
      case 0:
        return t('No contributions on this proposal');
      case 1:
        return t('Other person have also had their say');
      default:
        return t('Other people have also had their say');
    }
  };

  const getAgreementsStatus = (agreementsCount: number) => {
    switch (agreementsCount) {
      case 0:
        return t('No reactions on comments');
      case 1:
        return t('Reaction on comments');
      default:
        return t('Reactions on comments');
    }
  };

  return (
    <StatsContainer>
      <StatsTopSection>
        <img
          src="/static/illustrations/humanity_celebration.png"
          alt="humanity celebrating"
        />
        {children}
        {loading && <Spinner />}
      </StatsTopSection>
      <div>
        {renderData && (
          <DataRow>
            <DataPoint>
              <DataPointIcon>
                <StatsContribution width={26} height={26} />
              </DataPointIcon>
              <h2>
                <strong>{contributions}</strong>
              </h2>
              <span>{getContributionStatus(contributions)}</span>
            </DataPoint>
            {displayAgreements && (
              <DataPoint>
                <DataPointIcon>
                  <ReactionIcon color="black" width={26} height={26} />
                </DataPointIcon>
                <h2>
                  <strong>{agreements}</strong>
                </h2>
                <span>{getAgreementsStatus(agreements)}</span>
              </DataPoint>
            )}
          </DataRow>
        )}
      </div>
    </StatsContainer>
  );
};
