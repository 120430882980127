export enum SCROLL_TO_ORIENTATIONS {
  HORIZONTALLY = 'horizontally',
  VERTICALLY = 'vertically',
}

interface ScrollToProps {
  elementId: string;
  orientation: SCROLL_TO_ORIENTATIONS;
  mod?: number /* If you want to scroll to item but add more (or less) scroll */;
  scrollProps?: ScrollIntoViewOptions;
}
export const scrollTo = ({
  elementId,
  orientation = SCROLL_TO_ORIENTATIONS.HORIZONTALLY,
  mod = 0,
  scrollProps = {
    behavior: 'smooth',
  },
}: ScrollToProps) => {
  const orientationProp = orientation === 'vertically' ? 'top' : 'left';
  const element = document.getElementById(elementId);
  const dims =
    element.getBoundingClientRect()?.[orientationProp] +
    window.pageYOffset -
    mod;
  window.scrollTo({ [orientationProp]: dims, ...scrollProps });
};
