import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const Container = styled.div`
  width: 80%;
  & > h2,
  p {
    font-family: ${({ theme }) => theme.fontFamily};
  }
`;

export const CardContainer = styled.div<{
  content: string;
  isProjectCard?: boolean;
}>`
  position: relative;
  width: 18.6875rem;
  max-width: 18.75rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  & > div {
    height: 17.9375rem;
    position: relative;
  }
  & > p {
    font-family: ${({ theme }) => theme.fontFamily};
    font-size: 1.25rem;
    font-weight: 500;
  }
  ${({ isProjectCard }) =>
    isProjectCard &&
    `
    img {
      height: 9rem;
    }
  `}
  & > div:hover {
    &:after {
      position: absolute;
      pointer-events: none;
      top: 0;
      text-align: center;
      padding-top: 3.5rem;
      border-radius: 0.3125rem;
      width: 100%;
      height: 9rem;
      font-size: 1.5rem;
      font-weight: 700;
      color: ${({ theme }) => theme.colors.white[500]};
      background-color: ${({ theme }) =>
        theme.colors.green[900]}cc; // cc = 80% opacity

      content: ${({ content }) => `"${content} ➜"`};
      cursor: pointer;
    }
  }

  header {
    height: unset;

    position: absolute;
    right: 0;
    margin: 0.625rem;

    background-color: ${({ theme }) => theme.colors.white[500]};
    border-radius: 0.3125rem;
    div {
      height: unset;
    }
  }
`;

export const NextProposalContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  p {
    font-size: 1.25rem;
    font-weight: 600;
    align-self: flex-start;
    margin: 0;
  }

  button {
    text-align: center;
    margin-top: 1rem;
    padding: 0.625rem 1.25rem;
    width: fit-content;
    cursor: pointer;
    padding-top: 0.5rem;
    border: 1px solid ${({ theme }) => theme.colorMappings.greyButtonBorder};
    color: ${({ theme }) => theme.colorMappings.greyButtonLabel};
    border-radius: 0.3125rem;
    background: ${({ theme }) => theme.colors.white[500]};
    &:hover {
      border: 1px solid
        ${({ theme }) => theme.colorMappings.greyButtonBorderHover};
      color: ${({ theme }) => theme.colorMappings.greyButtonLabelHover};
    }
  }
`;

export const NextProjectContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
