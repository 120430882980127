import * as React from 'react';

export const StatsContribution = ({
  width = 20,
  height = 20,
  viewBox = '0 0 25 25',
  color = 'black',
  ...props
}) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0.49725 24.9243C0.36725 24.9243 0.227265 24.8743 0.137265 24.7743C0.00726487 24.6443 -0.0327205 24.4443 0.0272795 24.2743L1.74725 19.1343C0.60725 17.2843 0.00725998 15.1443 0.00725998 12.9243C0.00725998 6.30432 5.38726 0.924316 12.0073 0.924316C18.6273 0.924316 24.0073 6.30432 24.0073 12.9243C24.0073 19.5443 18.6273 24.9243 12.0073 24.9243C9.92726 24.9243 7.87727 24.3843 6.07727 23.3543L0.647275 24.9043C0.587275 24.9143 0.54725 24.9243 0.49725 24.9243ZM6.13726 22.3143C6.22726 22.3143 6.31727 22.3343 6.39727 22.3843C8.08727 23.3943 10.0273 23.9243 11.9973 23.9243C18.0673 23.9243 22.9972 18.9943 22.9972 12.9243C22.9972 6.85432 18.0673 1.92432 11.9973 1.92432C5.92725 1.92432 0.99725 6.85432 0.99725 12.9243C0.99725 15.0243 1.58727 17.0543 2.70727 18.7943C2.78727 18.9243 2.80726 19.0843 2.75726 19.2243L1.26727 23.6843L5.99725 22.3343C6.04725 22.3243 6.08726 22.3143 6.13726 22.3143Z"
      fill={color}
    />
    <path
      d="M17.4973 10.9243H6.49731C6.21731 10.9243 5.99731 10.7043 5.99731 10.4243C5.99731 10.1443 6.21731 9.92432 6.49731 9.92432H17.4973C17.7773 9.92432 17.9973 10.1443 17.9973 10.4243C17.9973 10.7043 17.7773 10.9243 17.4973 10.9243Z"
      fill={color}
    />
    <path
      d="M17.4973 13.9243H6.49731C6.21731 13.9243 5.99731 13.7043 5.99731 13.4243C5.99731 13.1443 6.21731 12.9243 6.49731 12.9243H17.4973C17.7773 12.9243 17.9973 13.1443 17.9973 13.4243C17.9973 13.7043 17.7773 13.9243 17.4973 13.9243Z"
      fill={color}
    />
    <path
      d="M17.4973 16.9243H6.49731C6.21731 16.9243 5.99731 16.7043 5.99731 16.4243C5.99731 16.1443 6.21731 15.9243 6.49731 15.9243H17.4973C17.7773 15.9243 17.9973 16.1443 17.9973 16.4243C17.9973 16.7043 17.7773 16.9243 17.4973 16.9243Z"
      fill={color}
    />
  </svg>
);
