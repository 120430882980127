import fetch from 'isomorphic-unfetch';
import { captureException } from '@sentry/node';
import { isLive } from 'Shared/utils';

interface SurveyRequestParams {
  id: string;
}

export const sendSurveyRequest = async (
  props: SurveyRequestParams
): Promise<void> => {
  if (!isLive) return;

  const { id } = props;

  try {
    const response: Response = await fetch(`/api/delighted?id=${id}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
    });

    const data = await response.json();

    if (!response.ok) {
      throw new Error(data);
    }
  } catch (error) {
    captureException(
      `Error in sendSurveyRequest @ sendSurveyRequest.ts : ${error}`
    );
  }
};
