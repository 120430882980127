import { CircularProgress } from '@material-ui/core';
import styled, { css } from 'styled-components';

export const StatsContainer = styled.div`
  ${({ theme }) => css`
    ${theme.breakpoints.down('xs')} {
      img {
        width: 15.5625rem;
        height: 8.6875rem;
      }
    }
  `}
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  img {
    max-width: 19.375rem;
    height: 100%;
  }
`;

export const StatsTopSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
`;

export const DataRow = styled.ul`
  list-style: none;
  margin: 0 auto;
  padding-left: 0;
  display: flex;
  justify-content: space-evenly;
`;

export const DataPoint = styled.li`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 3rem;
  overflow: visible;
  color: #878787;
  font-family: ${({ theme }) => theme.fontFamily};

  h2 {
    margin: 0.5rem -2rem 0.25rem;
    color: black;
    font-size: 1.375rem;
  }

  span {
    margin: 0 -3rem;
    font-size: 0.875rem;
  }
`;

export const DataPointIcon = styled.div`
  border-radius: 50%;
  width: 3.75rem;
  height: 3.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f4f4f4;
`;

export const Spinner = styled(CircularProgress)`
  margin: 2rem auto;
  display: block;
`;
