import * as React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useRouter } from 'next/router';
import styled from 'styled-components';
import { Link } from 'Atoms';
import { changeRouteKeepParams } from 'Client/utils/url/changeRouteKeepParams';

interface ContributionVisibilityProps {
  projectName: string;
}

export const ContributionVisibility: React.FC<ContributionVisibilityProps> = ({
  projectName,
}) => {
  const { t, i18n } = useTranslation();
  const router = useRouter();
  return (
    <Wrapper>
      <div style={{ maxWidth: '50%' }}>
        <p>
          {t(
            'Your contribution will now be included in {{projectName}}’s evaluation.',
            { projectName }
          )}
        </p>
        <p>
          {t(
            'You’ll receive updates about {{projectName}} or reactions to your contributions.',
            { projectName }
          )}
        </p>
        <p>
          <Trans>
            {'Make sure you '}
            <Link
              noPadding
              to={`https://profile.commonplace.is/?lang=${i18n.language}`}
            >
              opted into communications
            </Link>
            {' or checkout '}
            <Link noPadding to={changeRouteKeepParams(router.asPath, '/news')}>
              project news.
            </Link>
          </Trans>
        </p>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.5rem 0;
  text-align: center;

  background-color: ${({ theme }) =>
    theme.colors.green[400]}66; // 66 = 40% opacity

  p {
    font-size: 1rem;
    color: ${({ theme }) => theme.colors.black[500]};
    margin: 0;
    &:first-child {
      margin-bottom: 1.5rem;
    }
  }

  a {
    cursor: pointer;
    font-weight: bold;
    color: ${({ theme }) => theme.colors.black[500]};
    text-decoration: underline;
  }
`;
