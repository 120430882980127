import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { captureMessage, captureException } from '@sentry/node';
import { useLazyQuery } from '@apollo/client';
import { useProject } from 'Client/utils/hooks';
import { getNearestProjects } from 'Client/services/project/getNearestProjects';
import { ProjectCard } from 'Atoms';
import { cpBrandName } from 'Client/constants/brand';
import { GET_PROGRAMMES } from 'Client/services/programme/getProgrammes.gql';
import { Header, ProgrammeWrapper, Wrapper } from './NearProjects.styles';

export const NearProjects = () => {
  const [getProgrammes] = useLazyQuery(GET_PROGRAMMES, {});
  const project = useProject();
  const { t, i18n } = useTranslation();
  const [nearProjects, setNearProjects] = React.useState([]);
  const [loadingTiles, setLoadingTiles] = React.useState(false);

  React.useEffect(() => {
    const getProjects = async () => {
      try {
        setLoadingTiles(false);
        const projectLng =
          project?.longitude || project?.location?.coordinates[0];
        const projectLat =
          project?.latitude || project?.location?.coordinates[1];
        if (!projectLng || !projectLat) {
          captureMessage(`Project ${project._id} has no location`);
          return;
        }
        const nearestMongoProjects = await getNearestProjects({
          latitude: projectLat,
          longitude: projectLng,
          currentProjectId: project._id,
          activeOnly: true,
          limit: 3,
          includeInternal: false,
        });
        if (!nearestMongoProjects) return;
        setLoadingTiles(true);
        const { data } = await getProgrammes({
          variables: {
            getProgrammesInput: {
              rules: {
                _id: {
                  condition: 'is-in',
                  value: nearestMongoProjects
                    .map((project) => String(project._id))
                    .join(';'),
                },
              },
              order: 'random',
              lang: i18n.language,
              customerName: project.customer,
            },
          },
        });
        const programmeTiles = data?.getProgrammes || [];
        programmeTiles.shift();
        /* shift() as the first one will be displayed in the main section */
        setNearProjects(programmeTiles);
      } catch (err) {
        captureException(err);
      } finally {
        setLoadingTiles(false);
      }
    };
    getProjects();
  }, [i18n.language, project]);

  if (loadingTiles) return <p>{t('Loading near projects...')}</p>;
  if (!nearProjects.length && !loadingTiles) return null;

  return (
    <Wrapper>
      <Header>
        <h2>
          {t('See how {{cpBrandName}} is making a difference: ', {
            cpBrandName,
          })}
        </h2>
        <p>
          {t(
            'Have a look at these projects near you and help in improving your community.'
          )}
        </p>
      </Header>
      <ProgrammeWrapper>
        {nearProjects.map((project) => (
          <ProjectCard project={project} key={project.id} />
        ))}
      </ProgrammeWrapper>
    </Wrapper>
  );
};
